// Import all the tools needed to customize the theme and extract parts of it
@use '@angular/material' as mat;
@import '@angular/material/theming';

// Define a mixin that accepts a theme and outputs the color styles for the component.
@mixin treetable-widgets-theme($theme) {
  // Extract whichever individual palettes you need from the theme.
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $background: map-get($theme, background);

  $palettes: primary accent warn;
  $hues: 50 100 200 300 400 500 600 700 800 900 default lighter darker A100 A200 A400 A700;
  $opacities: 5 10 15 20 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95;

  //mattree
  .theme-tree-table{
    width:100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-left: solid 1px mat.get-color-from-palette($primary, A400, 0.12);
    border-bottom: solid 1px mat.get-color-from-palette($primary, A400, 0.12);
    thead, mat-tree {
      tr, mat-tree-node {
        th, td {
          border-right: solid 1px mat.get-color-from-palette($primary, A400, 0.12);
          border-top: solid 1px mat.get-color-from-palette($primary, A400, 0.12);
          padding: 5px;
          text-align: center;
          
        }
        th {
            font-weight: bold;
            text-align: center;
           
          }
          // th:nth-child(even) {
          //   background: mat-color($primary, A400, 0.07); 
          // }
          td:nth-child(even) {
            background:  mat.get-color-from-palette($primary, A400, 0.04); 
          }
       /* th.mat-sort-header-sorted {
        color: mat-contrast($primary, A100);
        } */
        th.mat-header-cell:first-of-type {
          padding: 5px;
        }
        td:first-child {
          padding-right: 25px;
          text-align: start;
        }
      } 
      mat-tree-node {
        display: table-row;
     }
      mat-tree-node:hover {
        background:mat.get-color-from-palette($accent, A400 , 0.08); 
      }
    }
    mat-tree {
        display: table-row-group;
        width: 100%;
    }
    button:disabled{
      height: 20px;
    }

    .bg-color{
      background: mat.get-color-from-palette($primary, A400, 0.07); 
    }
    .style-class{
      width: 100px;
    }
    .mat-cell {
      font-size: 12px;
    }
  }
}