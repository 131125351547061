@use '@angular/material' as mat;
$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';
$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~typeface-roboto/index.css";

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');

@import '~@trackback/styles/generic';
@import '~@trackback/styles/angular';
@import '~@trackback/styles/material';
@import '~@trackback/styles/theme';

@include mat.core();
@include mat.all-component-themes($tb-light-theme);

@import '../projects/trackback/ng-widgets/src/styles/themes/widgets.theme';
@include widgets-theme($tb-light-theme);

@import 'styles/themes/treetable-widgets.theme';
@include treetable-widgets-theme($tb-light-theme);
@import './styles/treetable.scss';
@import './styles/playlist.scss';

@import '@angular/material/theming';

@mixin style-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);

  // for login form
  .loginform {
      width: 100%;
  }

  tb-datepicker-field .mat-form-field {
      display: inline !important;
  }

  //for search box
  .cdk-overlay-pane-select-search {
      transform: none !important;
  }

  // For status dropdown  size
  div .mat-tab-body-content {
      width: 100% !important;
  }

  // audio popup
  .mat-drawer:not(.mat-drawer-side) {
      position: fixed;
  }

  .mat-drawer-backdrop {
      position: fixed !important;
  }

  // page shows double scrolling.
  .page-content-viewport {
      max-height: calc(100vh - 64px) !important;
    }

  mat-sidenav {
      .mat-drawer-inner-container {
          min-width: 280px;
      }
  }

  .icon_custom_mob button span mat-icon {
    color: mat.get-color-from-palette($primary, '400-contrast')
  }
  // playlist fab icon
  .icon_custom_mob button {
    position: fixed;
    top: 50px;
    right: 10px;
    background-color: mat.get-color-from-palette($accent, 500);
    z-index: 11;
  }
  .icon_custom button span mat-icon {
    color: mat.get-color-from-palette($primary, '400-contrast')
  }
  .icon_custom button {
    background-color: mat.get-color-from-palette($accent, 500);
    position: absolute;
    right: 62px;
    top: 41px;
  }

  .wordwrap{
      white-space: normal;
      word-wrap: break-word !important;
  }

  // underline
  .bottomline {
      border-bottom: 1px solid mat.get-color-from-palette($primary, '300-contrast');
  }

  .wordwrap-dealer {
      white-space: initial;
  }

  .mat-select-panel {
      max-height: 350px !important;
  }

  .mat-icon-button {
    &.cdk-program-focused .mat-button-focus-overlay {
      opacity: 0 !important;
    }
  }

  tb-texthtml table {
    background-color: mat.get-color-from-palette($primary, '400-contrast');
  }

  tb-email img {
      width: 100% !important;
  }

  // set the height for dialogueBox
  .mat-dialog-container {
    height: auto !important;
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    // set the height for dialogueBox
    .mat-dialog-container {
      overflow: auto !important;
      max-height: 525px !important;
    }
  }

  #leads-page {
      .mat-expansion-panel-body {
          background-color: mat.get-color-from-palette($primary, 50);
          padding-top: 24px !important;
      }
  }

  #calls-page {
    .mat-expansion-panel-body {
      background-color: mat.get-color-from-palette($primary, 50);
      padding-top: 24px !important;
    }
  }

  .cursor-ponter {
    cursor: pointer;
  }

  tb-toolbar {
    border-bottom: 1px solid rgba(0,0,0,.12)
  }

}

.mat-card-header-text {
  overflow: hidden;
}

.mat-card-avatar {
  height: 40px;
  width: 40px;
  margin-right: 8px;
  border-radius: 50%;
  flex: 0 0 40px;
}

tb-text.mat-card-avatar {
  line-height: 40px;
}

@include style-theme($tb-light-theme);
